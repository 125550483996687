.dim {
  position: absolute;
  left: 0;
  top: 0;
  font-family: monospace;
  font-size: 14px;
  font-weight: 700;
  width: 60px;
  height: px;
  text-align: center;
  border: none;
  color: white;
  background: rgba(45, 51, 56, 0.7);
  border-radius: 2px;
  padding: 1px 2px;
  z-index: 5;
}
