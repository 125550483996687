.auth {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #2d3338;
}
.auth-page {
  width: 100%;
}

.form-control {
  border: none !important;
  background-color: #e2e3e9 !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  padding: 0.5rem 0.75rem !important;
}

.form-group label {
  color: rgba(66, 71, 76, 0.9);
}

.required-field:after {
  content: ' *';
  color: rgba(66, 71, 76, 0.9);
}

div.made-by {
  text-align: center;
  height: 18vh;
  font-size: 20px;
  padding-top: 60px;
  color: white;
  font-weight: normal;
}

.nodecoration {
  text-decoration: none;
}

div.authCard {
  max-width: 450px;
  padding: 46px;
  box-shadow: 0px 0px 1px rgb(40 41 61 / 8%), 0px 0.5px 2px rgb(9, 97, 112 / 16%);
  height: auto;
  width: 86%;
}

.signupForm .authCard {
  padding: 20px 50px;
}
.addressForm .authCard {
  padding: 22px 50px;
}
.login .authCard {
  padding: 46px;
}
.forgotPassword .authCard {
  padding: 48px 50px;
}
.resetSuccessful .authCard {
  padding: 40px;
}

.authCard div.form-check {
  display: flex;
}
.authCard div.form-check input[type='checkbox'] {
  margin-left: 0rem;
  margin-right: 0.5rem;
  border: 2px solid #c7c8d9;
  width: 16px;
  height: 16px;
  border-radius: 0px;
}
.authCard a {
  font-size: 14px;
}
.authCard .cardheader {
  font-weight: 600;
}
.authCard div.form-check label {
  font-size: 14px;
}
.authCard label,
.authCard div.link-login {
  font-size: 14px;
  margin-top: 20px;
}
@media (max-width: 576px) {
  div.authCard {
    padding: 2rem;
  }
  .authCard .card-body {
    display: block;
  }

  div.made-by {
    margin-top: 10px;
    padding-top: 0px;
  }
}

@media (max-height: 800px) {
  div.made-by {
    margin-top: 10px;
    padding-top: 0px;
  }
}

div.made-by img {
  width: 150px;
  height: auto;
}

.login-button,
.sign-up-button,
.save-address-button,
.complete-signup,
.reset-successful-login-button,
.send-reset-email {
  font-size: 20px !important;
  line-height: 24px !important;
  padding: 8px 24px !important;
}

.terms-conditions-container,
.privacy-policy-container {
  display: flex;
  flex-direction: column;
  background-color: #f5f5fa;
  height: 100%;
}

.terms-conditions-container,
.privacy-policy-container .heading {
  font-weight: 600;
  margin-bottom: 24px;
}
