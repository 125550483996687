body {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  font-family: 'Inter', normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  /* background-color: #2D3338; */
  background-color: #eee;
}
.card {
  border-radius: 1em;
  margin: auto;
}
.text-right {
  margin-bottom: 10px;
}
.header-card {
  height: 54px;
  margin: 4vh 0;
}
.header-card .card-body {
  padding: 7px 6px 6px 6px;
  font-size: 20px;
}
