.canavs-root {
  position: absolute;
  width: 50vw;
  height: 50vh;
  border: none;
  outline: none;
}

#canvas3d {
  width: 100% !important;
  height: 100% !important;
  border-radius: 10px;
  outline: none;
}

#labelDiv {
  height: 30px;
  width: 70px;
  top: 0px;
  left: 0px;
  z-index: 3;
  opacity: 0.8;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  border-radius: 15px;
  color: #000;
  background: #fff;
  text-align: center;
  vertical-align: middle;
  line-height: 2.5;
}

#labelInputDiv {
  height: 30px;
  width: 100px;
  top: 30px;
  left: 3px;
  z-index: 3;
  opacity: 0.8;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  border-radius: 3px;
  color: #000;
  background: #fff;
  text-align: center;
  vertical-align: middle;
  line-height: 2.5;
}

.realValue {
  max-width: 100px;
}

.k-upload-files {
  display: none;
}

.k-i-loading {
  display: none;
}

.uploadInput {
  position: absolute;
  text-align: center;
  z-index: 3;
}

.uploadModel {
  background-color: #2d3338;
  color: white;
  height: 40px;
  border-radius: 4px;
  text-align: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
}

.gui-topbar {
  display: none;
}

.gui-sidebar {
  display: none;
}

.gui-resize {
  display: none;
}

#viewport {
  /* display: none; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: 2;
}

#cards {
  position: absolute;
  top: 178px;
  left: 0;
  width: 100vw;
  height: 70%;
  z-index: 3;
  background-color: #fafafc;
  padding: 0 15%;
  overflow: auto;
}

#canvas {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 10px;
}

.canvasWrapper {
  position: relative;
  width: 100%;
  height: 65vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (max-width: 768px) {
  .canvasWrapper {
    height: 80vh;
  }
}

.canvas-root {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
}

.dropzone {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.chevron-icon {
  display: none;
}
@media (max-width: 768px) {
  .chevron-icon {
    display: block;
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    z-index: 5;
    color: #737373;
    font-size: 40px;
    border-radius: 5px;
    padding: 0.2rem;
    border: 2px solid #737373;
  }
}

#loading {
  z-index: 5;
}

#mirrorDiv {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 25% !important;
  height: 30% !important;
  z-index: 4;
  visibility: hidden;
}

#mirror {
  width: 100%;
  height: 100%;
  display: block;
}

.row > * {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
