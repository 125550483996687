.header {
  position: absolute;
  padding-left: 10px;
  z-index: 2;
  top: 90px;
  background-color: #fff;
  width: 75%;
  height: 6%;
  left: 12.5%;
  border-radius: 10px;
  display: table;
}
.header > p {
  display: table-cell;
  width: 200px;
  line-height: normal;
  vertical-align: middle;
  font-size: 20px;
  font-weight: 500;
  margin: 0 10px;
}
.controller {
  z-index: 3;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
  text-align: center;
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px;
  margin-left: 4vw;
}

.controller-text {
  font-size: 40px !important;
  margin-bottom: 8px !important;
}

.controller legend {
  font-size: 12px;
  font-weight: 600;
  color: #212529;
}
.chevron-icon {
  display: none;
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .controller {
    max-width: 200px;
  }
}
@media (max-width: 992px) {
  .controller {
    margin-left: 2vw;
  }
}
@media (max-width: 768px) {
  .controller {
    background-color: rgb(45 51 56);
    position: fixed;
    width: 200px;
    animation: slide 0.5s 1;
    margin-left: 0;
    top: calc(114px + 4vh);
    z-index: 5;
  }
  @keyframes slide {
    0% {
      left: 0;
      opacity: 0;
    }
    100% {
      left: 12px;
      opacity: 1;
    }
  }
  .controller p {
    color: white;
  }
  .controller legend {
    color: white;
  }
  .controller span {
    color: white;
  }
  .controller .MuiFormLabel-root.Mui-focused {
    color: white;
  }
  .controller button {
    color: white;
  }
  .controller button.inactiveBtn {
    color: black;
  }
  .controller > div > label > span {
    color: black;
  }
  .controller button.subBtn.btn-light {
    color: black;
  }

  .chevron-icon {
    display: block;
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    z-index: 5;
    color: #737373;
    font-size: 40px;
    border-radius: 5px;
    padding: 0.2rem;
    border: 2px solid #737373;
  }
}
.controller > p {
  font-size: 30px;
  font-weight: 500;
}
.activeBtn {
  color: white !important;
  background-color: #555a5e !important;
}
.inactiveBtn {
  color: black !important;
  background-color: #f2f2f5 !important;
}
.controlBtn {
  border-radius: 100px !important;
  outline: none;
  width: 130px;
  height: 10%;
  margin: 4px 3px;
  padding: 10px !important;
  box-shadow: none !important;
  font-weight: 600 !important;
}
.controller .downloadBtn {
  margin-top: 36px;
  width: 130px;
  font-weight: 500 !important;
  font-size: 18px;
}
button img {
  float: left;
  margin: 2px 5px;
  width: 20px;
}
.headerBtn {
  display: table-cell;
  float: right;
  vertical-align: middle;
  border-radius: 20px;
  outline: none;
  height: 40px;
  background-color: #f2f2f5;
  text-align: center;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 15px;
}
.card {
  height: 54px;
  margin: 2vh 0;
  border: none !important;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
}
.card > .card-body {
  padding: 10px;
  font-size: 20px;
}
.card > .card-body > button.btn {
  border-radius: 20px;
}
.clearBtn,
.undoBtn {
  width: 100px;
  text-align: center;
  margin: auto 8px !important;
  font-weight: 600 !important;
}
@media (max-width: 576px) {
  .clearBtn,
  .undoBtn {
    margin-left: 8px;
    width: 50px;
  }
  .card > .card-body {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
}
.vl {
  display: table-cell;
  float: right;
  border-left: 2px solid #737373;
  height: 20px;
  margin: 6px 30px;
  top: 0;
}
.iconBtn {
  margin-right: 8px;
  width: 50px;
}
.img-w20 {
  width: 20px;
  height: 20px;
  margin: 2px;
}
.rc-input-number-input {
  border: none;
  outline: none;
  background-color: #dbdbdb;
  width: 130px;
  height: 30px;
  margin: 2px 0px;
  text-align: center;
  border-radius: 5px;
  padding-right: 37px;
}
.applyBtn {
  color: black;
  width: 130px;
  height: 30px;
  padding: 0px !important;
  margin: 8px 0px;
}
.subBtn {
  color: black;
  width: 60px;
  height: 24px;
  padding: 0px !important;
  margin: 2px;
  border-radius: 20px !important;
  font-size: 12px !important;
}
.btn-light {
  color: black;
  background-color: #f4f4f6;
}
.btn-dark {
  color: white;
  background-color: #555a5e;
}

label {
  position: relative;
  margin-bottom: 0;
}
.unit {
  position: absolute;
  top: 5px;
  right: 5px;
}
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0px;
  margin: 8px;
}
.custom-control {
  padding-left: 0px;
}
.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.row-3 {
  margin: 40px 0px;
}
.MuiFormControl-root {
  margin-top: 10px;
}
.MuiFormControlLabel-label {
  font-size: 12px !important;
}
.MuiFormControlLabel-labelPlacementBottom {
  margin: 0 !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.btn:focus {
  outline: none !important;
  outline-offset: none !important;
}

.controller-error {
  z-index: 3;
  border-radius: 16px;
  text-align: center;
  align-items: center;
  margin-left: 4vw;
  margin-top: 12px;
}

.alert-danger{
  padding: 8px !important;
}
