#controlsWrapper {
  position: absolute;
  top: 80px;
  right: 50px;
  /* align-items: center; */
  width: 200px;
  height: 100px;
}

.container {
  position: relative;
  align-items: center;
  width: 200px;
  flex: 0.5;
  display: none;
}

.slider {
  margin-top: 4px;
  color: white;
}

.text-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.label {
  color: #3f51b5;
  font-family: sans-serif;
  font-size: 15px;
  font-weight: 300;
}

.value {
  color: #3f51b5;
  font-family: sans-serif;
  font-size: 15px;
  font-weight: bold;
}

.slider-tooltip {
  margin-left: 8px;
  cursor: pointer;
}

.navbar {
  padding: 0.5rem 9%;
}

.navLinks {
  font-weight: 600;
  font-size: 16px !important;
  cursor: pointer;
  margin: auto 10px !important;
}

@media (max-width: 992px) {
  div.navbar-nav {
    position: absolute;
    left: 0;
    top: 60px;
    z-index: 7;
    width: 100%;
    background-color: #212529 !important;
    padding: 1rem;
    animation: nav-slide 0.5s 1;
  }
  @keyframes nav-slide {
    0% {
      top: 0;
      opacity: 0;
    }
    100% {
      top: 60px;
      opacity: 1;
    }
  }
}
