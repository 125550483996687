/***************** Download file modal styles ***************/

::-webkit-scrollbar-track {
  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #000000;
}

.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85%;
}
.modal-content {
  width: 450px !important;
  font-family: 'Inter', sans-serif !important;
  background: #ffffff !important;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16) !important;
  border-radius: 16px !important;
  padding: 35px 50px;
}
.modal-header {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border: none !important;
  padding: 0px !important;
  margin-bottom: 20px;
}
.modal-header .modal-title {
  justify-content: flex-start !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 32px !important;
  line-height: 44px !important;
  color: #000000 !important;
  text-align: left;
  width: 100%;
}

.modal-body {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.2px;
  color: #8e90a6;
  padding: 0px !important;
  margin-bottom: 20px;
}
.modal-body p {
  margin-bottom: 0px !important;
}

.modal-body a {
  text-decoration: none;
}
.bottom-text {
  text-align: left;
}
.bottom-text p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  width: 75%;
  letter-spacing: 0.2px;
  color: #8e90a6;
}

.download-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.download-btn button {
  width: 100%;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.modal-footer {
  border: none !important;
  padding: 0px !important;
}

@media (max-width: 375px) {
  .modal-header .modal-title {
    font-size: 24px !important;
  }

  .modal-body {
    padding: 1rem 1rem !important;
  }
}
