.subscription-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f5f5fa;
}
.subscription {
  background-color: #f5f5fa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  align-items: center;
  min-height: 100%;
  margin-bottom: 40px;
}
.subscription h2 {
  color: #2d3338;
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
}
.subscription p {
  color: rgba(45, 51, 56, 0.7);
  margin-bottom: 25px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}
.subscription ul {
  list-style: none;
  background-color: white;
  margin: 0;
  padding: 0;
  border-radius: 22px;
  margin-bottom: 50px;
}
.subscription ul li {
  display: inline-block;
  background-color: white;
  color: #848199;
  min-width: 111px;
  padding: 10px 20px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 22px;
  font-size: 12px;
}
.subscription ul li:hover {
  cursor: pointer;
}
.subscription ul li.active {
  background-color: #2d3338;
  color: white;
  box-shadow: 0px 5px 7px rgba(41, 51, 56, 0.23);
}
.subscription-card {
  min-width: 180px;
  max-width: 300px;
  min-height: 300px;
  padding: 40px 40px 36px 40px;
  border-radius: 22px;
  background-color: white;
  margin-right: 40px;
}
.subscription-card h5 {
  font-weight: 800;
  letter-spacing: 1.3px;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 14px;
}
.subscription-card h2 {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 36px;
}
.subscription-card h2 > span {
  font-size: 13px;
  font-weight: normal;

  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: #848199;
}
.subscription-card.active {
  margin-left: 8px;
  margin-right: 32px;
  background-color: #2d3338;
  color: white;
  box-shadow: 0px 42px 34px rgba(41, 51, 56, 0.23);
  background-image: url('../../assets/img/card_background.svg');
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 40%;
}
.subscription-card.active h2 {
  color: white;
}
.subscription-card.active h2 > span {
  color: white;
}
@media (max-width: 576px) {
  .subscription {
    padding: 50px 0;
  }
  .subscription ul {
    margin-bottom: 0;
  }
  .subscription-card.active {
    transform: translateY(0);
  }
}
.form-check {
  vertical-align: middle;
  margin-bottom: 8px !important;
}
.form-check input[type='checkbox'] {
  margin: 0;
  margin-top: 0.25em;
  margin-left: -1.5em;
  /* border-radius: 50%; */
}
.form-check .form-check-input:checked:disabled {
  background-color: rgba(45, 51, 56, 0.1);
  border-color: rgba(45, 51, 56, 0.1);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  color: #2d3338;
  opacity: 1;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 100%;
}
.form-check .form-check-label {
  color: #848199;
  font-weight: normal;
}
.form-check .form-check-input:checked:disabled ~ .form-check-label {
  opacity: 1;
  font-size: 15px;
}
.subscription-card.active p,
.subscription-card.active .form-check-label {
  color: white;
}
.subscription-card.active .form-check .form-check-input:checked:disabled {
  background-color: rgb(255 255 255 / 10%);
  border-color: rgb(255 255 255 / 10%);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  color: #2d3338;
  opacity: 1;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 100%;
}
.subscription-card p {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.subscription-card button {
  margin-top: 20px;
  border-radius: 22px;
  padding: 0.5rem 0.5rem;
  border: none;
  font-weight: bold;
  height: 43px;
  background: #2d3338;
  color: white;
  font-size: 15px;
}
.subscription-card.active button {
  background-color: white;
  color: #2d3338;
}
.subscription-card button:focus,
.subscription-card button:active:focus {
  box-shadow: none;
}
.subscription-card.active > div:first-child,
.subscription-card > div:first-child {
  text-align: right;
  margin-bottom: 10px;
}
.subscription-card.active div > span {
  text-transform: uppercase;
  color: white;
  font-weight: normal;
  padding: 5px 15px;
  font-size: 10px;
  background: rgb(255 255 255 / 10%);
  border-radius: 15px;
}
.subscription-card div > span {
  text-transform: uppercase;
  color: black;
  font-weight: normal;
  padding: 5px 15px;
  font-size: 10px;
  background: rgb(0 0 0 / 10%);
  border-radius: 15px;
}
